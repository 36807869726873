<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="openMenu()">
                        <ion-icon :icon="menu" slot="start" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="primary">
                    <ion-button @click="loadTimbrature()">
                        <ion-icon slot="start" :icon="refresh" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white" style="text-align: center;">Presenze</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true" ionScroll="scrollFunction($event)">
            <div v-if="loading" class="loading_spinner">
                <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>

            <div class="page_content" v-else>
                <!-- QRCode modal -->
                <Transition name="fade-transition">
                    <div v-if="showQrCode" class="modal_straordinario">
                        <div class="body_modal_container">
                            <div class="title_qr">Inquadra il codice QR per timbrare</div>
                            <div class="qr_code">
                                <qr-stream @decode="onDecode" class="mb">
                                    <div class="frame"></div>
                                </qr-stream>
                            </div>

                            <!-- <div class="confirmation_text">
                                {{ confirmationText }}
                            </div>

                            <button @click="handleQrCodeTimbratura()" class="btn_salva_straordinario" :disabled="disabledQrCodeButton">Salva</button> -->
                            <button @click="chiudiQrCode()" class="btn_close_modal">Chiudi</button>
                        </div>
                    </div>
                </Transition>

                <!-- Straordinario modal -->
                <Transition name="fade-transition">
                    <div v-if="showStraordinario" class="modal_straordinario">
                        <div class="body_modal_container">
                            <div v-if="dipendenteSettings.dipendenti_consenti_straordinari === '1' && consentiModificaStraordinario == '1'" class="field">
                                <div class="title">Straordinario effettuato</div>
                                <!-- <ion-input v-model="straordinarioValue" type="number"> </ion-input> -->
                                <div class="picker_straordinario">
                                    <div style="width: 100%">
                                        <p style="margin: 8px 0; display: block;">Ore</p>
                                        <ion-input v-model="currentHourStraordinario" type="number" min="0" max="23" step="1" inputmode="numeric"></ion-input>
                                    </div>
                                    <div style="width: 100%">
                                        <p style="margin: 8px 0; display: block;">Minuti</p>
                                        <ion-input
                                            v-model="currentMinutesStraordinario"
                                            type="number"
                                            min="0"
                                            max="59"
                                            step="1"
                                            inputmode="numeric"
                                        ></ion-input>
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <div class="title">Note</div>
                                <ion-textarea v-model="selectedPresenza.presenze_note" rows="4" placeholder="Informazioni presenza..."> </ion-textarea>
                            </div>
                            <!-- <div @click="deletePresenza(selectedPresenza.presenze_id)" class="btn_close_modal">Elimina presenza</div> -->
                            <div @click="editPresenza()" class="btn_salva_straordinario">Salva</div>
                            <div @click="chiudiStraordinario()" class="btn_close_modal">Chiudi</div>
                        </div>
                    </div>
                </Transition>

                <!-- QRCode modal -->
                <Transition name="fade-transition">
                    <div v-if="showNfcContainer" class="modal_nfc">
                        <div class="nfc_container">
                            <div class="title_nfc">Pronto per la scansione</div>
                            <div class="svg_container">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#086fa3" stroke="none">
                                        <path
                                            d="M4030 5101 c-71 -37 -98 -117 -66 -195 18 -44 54 -67 155 -99 209
                                        -68 394 -198 522 -366 62 -82 141 -238 170 -334 26 -87 51 -125 95 -143 78
                                        -32 158 -5 195 66 26 48 24 83 -11 192 -135 411 -457 733 -868 868 -109 35
                                        -144 37 -192 11z"
                                        />
                                        <path
                                            d="M2378 4929 c-558 -43 -1084 -286 -1489 -689 -314 -312 -529 -688
                                        -634 -1109 -159 -635 -44 -1313 313 -1856 108 -166 186 -261 321 -395 595
                                        -591 1438 -826 2241 -625 783 196 1420 785 1679 1551 89 262 124 476 124 754
                                        0 375 -73 694 -239 1035 -116 240 -269 452 -468 650 -406 404 -927 641 -1506
                                        685 -149 11 -186 11 -342 -1z m-1233 -1748 c36 -16 67 -56 260 -336 l220 -318
                                        5 286 c5 269 6 287 26 313 38 51 71 69 128 69 66 0 120 -36 136 -93 8 -25 10
                                        -205 8 -563 l-3 -526 -27 -35 c-33 -44 -102 -73 -150 -64 -69 13 -92 39 -304
                                        347 l-209 303 -5 -286 c-5 -268 -6 -288 -25 -314 -65 -86 -181 -82 -249 10
                                        -20 27 -21 40 -24 539 -2 282 0 531 3 555 11 72 75 128 150 131 11 0 38 -8 60
                                        -18z m1711 -7 c94 -70 96 -190 5 -251 -31 -21 -45 -23 -182 -23 l-149 0 0 -94
                                        0 -94 134 -4 c145 -3 164 -10 208 -69 48 -64 23 -166 -51 -207 -34 -19 -54
                                        -22 -164 -22 l-126 0 -3 -202 c-3 -194 -4 -204 -26 -236 -30 -42 -76 -65 -130
                                        -65 -49 0 -85 22 -119 73 l-23 33 0 534 c0 575 -1 561 53 611 13 13 37 27 53
                                        32 16 5 133 8 261 7 217 -2 233 -3 259 -23z m1154 0 c77 -23 186 -81 215 -116
                                        68 -81 26 -207 -80 -237 -44 -12 -64 -7 -154 37 -68 34 -81 37 -160 37 -71 -1
                                        -96 -5 -142 -27 -79 -36 -133 -89 -171 -166 -29 -59 -33 -76 -33 -152 0 -76 3
                                        -92 33 -152 96 -196 348 -259 498 -125 62 56 84 67 129 67 76 0 135 -54 143
                                        -130 6 -67 -9 -100 -79 -164 -132 -123 -323 -171 -514 -131 -184 38 -360 176
                                        -444 346 -94 191 -94 387 0 579 82 166 257 304 439 345 80 18 242 13 320 -11z"
                                        />
                                        <path
                                            d="M70 1148 c-39 -27 -70 -81 -70 -122 0 -39 34 -149 74 -244 142 -331
                                        415 -594 751 -725 178 -69 237 -72 299 -14 56 53 61 146 10 206 -18 22 -49 37
                                        -119 60 -291 94 -502 271 -635 534 -27 53 -59 130 -71 170 -12 39 -30 85 -42
                                        101 -40 57 -138 74 -197 34z"
                                        />
                                    </g>
                                </svg>
                            </div>

                            <div class="help_text">
                                Avvicina il Tag NFC
                            </div>
                            <button @click="chiudiNfcContainer()" class="btn_close_modal">Annulla</button>
                        </div>
                    </div>
                </Transition>

                <!-- Giustificativo modal -->
                <Transition name="fade-transition">
                    <div v-if="showGiustificativo" class="modal_straordinario">
                        <div class="body_modal_container">
                            <div class="field">
                                <div class="title">Giustificativo</div>
                                <ion-textarea v-model="selectedPresenza.presenze_note" rows="4" placeholder="Inserisci la motivazione"> </ion-textarea>
                            </div>
                            <div @click="saveGiustificativo()" class="btn_salva_straordinario">Salva</div>
                            <div @click="chiudiGiustificativo()" class="btn_close_modal">Chiudi</div>
                        </div>
                    </div>
                </Transition>

                <!-- Circolari modal -->
                <Transition name="fade-transition">
                    <div v-if="showModalCircolari" class="modal_straordinario">
                        <div class="body_modal_container">
                            <div class="content">
                                <p>
                                    <strong>{{ textCircolari }}</strong>
                                </p>
                                <p class="cta">Per visualizzarle vai nella pagina Documenti</p>
                                <!-- <router-link to="/documenti" class="cta">Per visualizzarle apri la pagina Documenti</router-link> -->
                            </div>
                            <div @click="chiudiCircolari()" class="btn_close_modal">Chiudi</div>
                        </div>
                    </div>
                </Transition>

                <!-- Giustificativo modal -->
                <Transition name="fade-transition">
                    <div v-if="badgeModalVisible" class="modal_straordinario">
                        <div class="body_modal_container body_modal_container_badge">
                            <div style="width: 250px; height: 250px;">
                                <img :src="b64Image" />
                            </div>
                            <div @click="chiudiBadgeModal()" class="btn_close_modal">Chiudi</div>
                        </div>
                    </div>
                </Transition>

                <!-- Orario attuale -->
                <div class="bg_dark">
                    <div class="timer_clock">{{ time }}</div>
                    <div class="today_date">{{ todayDate }}</div>
                    <div class="text">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="svg_wave">
                            <path
                                fill="#f2f2f2"
                                fill-opacity="1"
                                d="M0,192L120,165.3C240,139,480,85,720,106.7C960,128,1200,224,1320,272L1440,320L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                            ></path>
                        </svg>

                        <div class="btn_badge_container">
                            <div class="btn_badge" @click="openBadgeModal()">
                                <ion-icon :icon="qrCodeOutline"></ion-icon>
                                <span>Badge Virtuale</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ion-padding-horizontal">
                    <div class="section_title">Timbratura</div>
                    <!-- Timbra entrata ed uscita -->
                    <div class="buttons_container_presenza" v-if="dipendenteSettings.dipendenti_timbra_entrata_uscita === '1'">
                        <div v-if="dipendenteSettings.dipendenti_dichiara_reparto === '1'" class="selezione_reparto">
                            <ion-select
                                name="tipologia"
                                interface="action-sheet"
                                cancel-text="Annulla"
                                ok-text="Conferma"
                                v-model="repartoSelezionato"
                                value="1"
                                placeholder="Seleziona il reparto in cui timbrare"
                                @ionChange="setReparto($event.target.value)"
                            >
                                <ion-select-option v-for="reparto in reparti" :key="reparto.reparti_id" :value="reparto.reparti_id">
                                    {{ reparto.reparti_nome }}
                                </ion-select-option>
                            </ion-select>
                        </div>
                        <div class="buttons_timbrature_container">
                            <button class="btn_timbratura" @click="handleTimbraEntrata" :disabled="entrataTimbrata || isSavingTimbraturaEntrata">
                                <div class="icon">
                                    <ion-icon :icon="arrowForwardCircle"></ion-icon>
                                </div>
                                <span>Entrata</span>
                            </button>
                            <button class="btn_timbratura" @click="handleTimbraUscita" :disabled="uscitaTimbrata || isSavingTimbraturaUscita">
                                <div class="icon">
                                    <ion-icon :icon="arrowBackCircle"></ion-icon>
                                </div>
                                <span>Uscita</span>
                            </button>
                        </div>
                    </div>

                    <!-- Crea presenza completa -->
                    <div v-if="dipendenteSettings.dipendenti_timbra_posteriori === '1'" class="btn_presenza_container">
                        <button class="btn_timbratura" @click="createPresenza">
                            <div class="icon">
                                <ion-icon :icon="person"></ion-icon>
                            </div>
                            <span>Inserisci presenza</span>
                        </button>
                    </div>

                    <!-- Timbra entrata con QR e NFC-->
                    <div class="buttons_timbrature_container_qr_nfc">
                        <button v-if="dipendenteSettings.dipendenti_timbra_qrcode === '1'" class="btn_timbratura btn_qr_code" @click="openQrCode()">
                            <div class="icon">
                                <ion-icon :icon="qrCodeOutline"></ion-icon>
                            </div>
                            <span>QRCode</span>
                        </button>

                        <button v-if="dipendenteSettings.dipendenti_timbra_nfc === '1'" class="btn_timbratura" @click="nfcScan()">
                            <div class="icon">
                                <ion-icon :icon="phonePortrait"></ion-icon>
                            </div>
                            <span>NFC</span>
                        </button>
                    </div>
                </div>

                <!-- Reperibilità -->
                <div v-if="dipendenteSettings.dipendenti_reperibilita === '1'" class="ion-padding-horizontal">
                    <button class="btn_timbratura" @click="openReperibilita()">
                        <div class="icon">
                            <ion-icon :icon="calendar"></ion-icon>
                        </div>
                        <span>Reperibilità</span>
                    </button>
                </div>

                <!--Saldo ratei ferie permessi -->
                <div class="ion-padding-horizontal">
                    <div class="section_title">Richieste</div>
                    <div class="ratei_container">
                        <div class="single_rateo rateo_ferie">
                            <div class="title">Ferie</div>
                            <div class="value">
                                {{
                                    dipendente && dipendente.dipendenti_saldo_ferie
                                        ? dipendente.dipendenti_saldo_ferie
                                        : saldoRichieste && saldoRichieste.ratei_ferie_permessi_saldo_ferie
                                        ? saldoRichieste.ratei_ferie_permessi_saldo_ferie
                                        : 0
                                }}
                                h
                            </div>
                        </div>
                        <div class="single_rateo rateo_rol">
                            <div class="title">ROL</div>
                            <div class="value">
                                {{
                                    dipendente && dipendente.dipendenti_saldo_rol
                                        ? dipendente.dipendenti_saldo_rol
                                        : saldoRichieste && saldoRichieste.ratei_ferie_permessi_saldo_rol
                                        ? saldoRichieste.ratei_ferie_permessi_saldo_rol
                                        : 0
                                }}
                                h
                            </div>
                        </div>
                        <div class="single_rateo rateo_permessi">
                            <div class="title">Permessi</div>
                            <div class="value">
                                {{
                                    dipendente && dipendente.dipendenti_saldo_permessi
                                        ? dipendente.dipendenti_saldo_permessi
                                        : saldoRichieste && saldoRichieste.ratei_ferie_permessi_saldo_permessi
                                        ? saldoRichieste.ratei_ferie_permessi_saldo_permessi
                                        : 0
                                }}
                                h
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!presenze.length" class="no_presenze_container">
                    <div class="no_presenze">Nessuna presenza inserita</div>
                </div>

                <div v-else>
                    <div class="container_presenze">
                        <div class="section_title">Le tue timbrature</div>

                        <div v-for="timbratura in presenze" :key="timbratura.presenze_id" class="presenza" @click="apriStraordinario(timbratura)">
                            <div class="presenza_sx">
                                <div class="icon">
                                    <!-- Presenze legata ad una richiesta -->
                                    <ion-icon v-if="timbratura.presenze_richiesta" :icon="clipboard"></ion-icon>
                                    <!-- Ho sia entrata che uscita, mostro freccia sx -->
                                    <ion-icon v-else-if="timbratura.presenze_ora_inizio && timbratura.presenze_ora_fine" :icon="arrowBackCircle"></ion-icon>
                                    <!-- Ho solo entrata, mostro freccia dx -->
                                    <ion-icon v-else :icon="arrowForwardCircle"></ion-icon>
                                </div>
                                <div>
                                    <div v-if="timbratura.presenze_richiesta" class="title">{{ setRichiesta(timbratura.richieste_tipologia) }}</div>
                                    <div v-else class="title">{{ dayName(timbratura.presenze_data_inizio) }}</div>
                                    <div class="data">{{ dateFormat(timbratura.presenze_data_inizio) }}</div>
                                </div>
                            </div>

                            <div class="orari">
                                <span class="value">
                                    {{ timbratura.presenze_ora_inizio }}
                                    {{ timbratura.presenze_ora_fine ? " - " + timbratura.presenze_ora_fine : "" }}
                                </span>
                                <span v-if="timbratura.presenze_anomalia == 1" class="anomalia" @click.stop="apriGiustificativo(timbratura)">
                                    Anomalia
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonButtons,
    IonInput,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    onIonViewWillEnter,
    menuController,
    modalController,
} from "@ionic/vue";
import { refresh, menu, arrowBackCircle, arrowForwardCircle, phonePortrait, qrCodeOutline, calendar, clipboard, person } from "ionicons/icons";

import { computed, onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config.js";

//Lettura QR
import { QrStream } from "vue3-qr-reader";

import moment from "moment";

// Firebase
//import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { openToast } from "@/services/toast";

import apiImpostazioniHr from "@/services/impostazioni_hr";
import apiDipendenti from "@/services/dipendenti";
import apiPresenze from "@/services/presenze";
import apiReparti from "@/services/reparti";
import apiLogin from "@/services/login";
import apiNotifiche from "@/services/notification";
import apiRichieste from "@/services/richieste";
import apiDocumenti from "@/services/documenti";

import Reperibilita from "@/components/reperibilita/Reperibilita.vue";
import ModalCreaPresenza from "@/components/presenze/ModalCreaPresenza.vue";

export default {
    name: "Presenze",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        QrStream,
    },
    setup(context, props) {
        //console.log(context.root);

        const router = useRouter();
        const loading = ref(false);
        const richieste = ref([]);

        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));

        //Timbrature
        const presenze = ref([]);
        const entrataTimbrata = ref(false);
        const uscitaTimbrata = ref(false);

        //Real time clock
        const timer = ref(null);
        const time = ref(null);

        const mustDeclareGeolocation = ref(false);
        const rejectedGeolocation = ref(false);

        /**
         * ! Menu handler
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * ! Salva dati del dipendente
         */
        const dipendenteSettings = ref({
            dipendenti_timbra_entrata_uscita: null,
            dipendenti_consenti_straordinari: null,
            dipendenti_dichiara_reparto: null,
            dipendenti_reperibilita: null,
            dipendenti_timbra_qrcode: null,
            dipendenti_geolocalizzazione: null,
            dipendenti_timbra_posteriori: null,
            dipendenti_presenza_automatica: null,
        });

        async function getDipendenteData() {
            const res = await apiLogin.getUserDipendente(userID);
            if (res.status === 200 && res.data.status === 0) {
                const {
                    dipendenti_timbra_entrata_uscita,
                    dipendenti_consenti_straordinari,
                    dipendenti_dichiara_reparto,
                    dipendenti_reperibilita,
                    dipendenti_timbra_qrcode,
                    dipendenti_geolocalizzazione,
                    dipendenti_timbra_posteriori,
                    dipendenti_timbra_nfc,
                    dipendenti_presenza_automatica,
                } = res.data.data[0];

                dipendenteSettings.value = {
                    dipendenti_timbra_entrata_uscita,
                    dipendenti_consenti_straordinari,
                    dipendenti_dichiara_reparto,
                    dipendenti_reperibilita,
                    dipendenti_timbra_qrcode,
                    dipendenti_geolocalizzazione,
                    dipendenti_timbra_posteriori,
                    dipendenti_timbra_nfc,
                    dipendenti_presenza_automatica,
                };
            }
        }

        /**
         * ! Ottengo dati del modulo
         */

        const bloccaModificaStraordinario = ref(false);
        const consentiModificaStraordinario = ref(false);

        async function loadImpostazioniHr() {
            try {
                const res = await apiImpostazioniHr.getImpostazioniHr();

                if (res.status === 200 && res.data.status === 0 && res.data.data.length > 0) {
                    const settingsModulo = res.data.data[0];

                    // Check per blocco modifica straordinario nella stessa giornata della presenza
                    if (settingsModulo.impostazioni_hr_blocca_modifica_straordinario && settingsModulo.impostazioni_hr_blocca_modifica_straordinario == "1") {
                        bloccaModificaStraordinario.value = true;
                    } else {
                        bloccaModificaStraordinario.value = false;
                    }
                    console.log(bloccaModificaStraordinario.value);

                    // Check per consentire modifica straordinario
                    if (
                        settingsModulo.impostazioni_hr_permetti_modifica_straordinario &&
                        settingsModulo.impostazioni_hr_permetti_modifica_straordinario == "1"
                    ) {
                        consentiModificaStraordinario.value = true;
                    } else {
                        consentiModificaStraordinario.value = false;
                    }
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle impostazioni del modulo HR", "toast_danger");
            }
        }

        // update the time every second
        function interval() {
            setInterval(() => {
                time.value = Intl.DateTimeFormat(navigator.language, {
                    hour: "numeric",
                    minute: "numeric",
                }).format();
            }, 1000);
        }

        interval();

        const options = { day: "2-digit", month: "long", year: "numeric" };
        const todayDate = new Date().toLocaleString("it-IT", options);

        /**
         * ! Saldi ratei ferie e permessi
         */
        const saldoRichieste = ref(null);
        const loadingRatei = ref(false);
        async function loadRatei() {
            loadingRatei.value = true;
            try {
                const res = await apiRichieste.getRatei(dipendenteID);
                if (res.status === 200 && res.data.status === 0) {
                    saldoRichieste.value = res.data.data[0];
                } else {
                    openToast("Errore durante la richiesta dei saldi ferie/permessi", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei saldi ferie/permessi", "toast_danger");
            } finally {
                loadingRatei.value = false;
            }
        }
        loadRatei();

        /**
         * ! Utilities card presenza
         */
        function dayName(data_inizio) {
            return moment(data_inizio, "YYYY-MM-DD HH:mm:ss")
                .locale("it")
                .format("dddd");
        }

        function setRichiesta(tipoRichiesta) {
            switch (parseInt(tipoRichiesta)) {
                case 1:
                    return "Permesso";
                case 2:
                    return "Ferie";
                case 3:
                    return "Malattia";
                case 4:
                    return "Smart Working";
                case 5:
                    return "Trasferta";
                default:
                    return "Richiesta";
            }
        }

        /**
         * ! Mostra modale con badge dipendente
         */
        const badgeModalVisible = ref(false);

        function openBadgeModal() {
            badgeModalVisible.value = true;
        }
        function chiudiBadgeModal() {
            badgeModalVisible.value = false;
        }

        const b64Image = ref(null);
        async function loadBadgeDipendente() {
            const res = await apiDipendenti.getQrDipendente(dipendenteID);

            if (res.data.status == 0) {
                b64Image.value = null;
                openToast(`${res.data.txt}`, "toast_danger");
                return;
            }

            b64Image.value = `data:image/png;base64,${res.data}`;
        }
        loadBadgeDipendente();

        /**
         * ! Gestisce scansione con NFC per timbrare entrata o uscita
         */
        const scannedNFCValue = ref(null);
        const blockScan = ref(false);

        const showNfcContainer = ref(false);
        function chiudiNfcContainer() {
            scannedNFCValue.value = null;
            blockScan.value = true;
            showNfcContainer.value = false;
        }

        async function handleTimbraturaNfc(reparto_id) {
            blockScan.value = true;
            apiPresenze
                .timbraNFC(dipendenteID, reparto_id)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        //alert(JSON.stringify(response));
                        if (response.data.tipo === "entrata") {
                            //Inserisco nuova presenza
                            presenze.value = [response.data.data, ...presenze.value];
                            entrataTimbrata.value = true;
                        } else {
                            //Cerco la presenza appena modificata, la aggiorno e abilito di nuovo btn entrata
                            const presenzaDaChiudure = presenze.value.find((element) => element.presenze_id === response.data.data.presenze_id);
                            const presenzaChiusa = response.data.data;
                            if (presenzaDaChiudure) {
                                //console.log(presenzaDaChiudure);
                                presenzaDaChiudure.presenze_data_fine = presenzaChiusa.presenze_data_fine;
                                presenzaDaChiudure.presenze_ora_fine = presenzaChiusa.presenze_ora_fine;
                                presenzaDaChiudure.presenze_straordinario = parseFloat(presenzaChiusa.presenze_straordinario).toFixed(2);

                                entrataTimbrata.value = false;
                            }
                        }
                        openToast(response.data.txt, "toast_success");
                        scannedNFCValue.value = null;
                        showNfcContainer.value = false;
                        //blockScan.value = true;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    alert(error);
                    //blockScan.value = true;
                    openToast("Errore durante la timbratura", "toast_danger");
                });
        }

        const nfcScan = async () => {
            if (dipendenteSettings.value.dipendenti_presenza_automatica == "1") {
                openToast("Le tue timbrature vengono generate in automatico dal sistema", "toast_info");
                return;
            }

            blockScan.value = false;
            showNfcContainer.value = true;
            if ("NDEFReader" in window) {
                try {
                    // eslint-disable-line
                    const ndef = new NDEFReader(); // eslint-disable-line
                    await ndef.scan();
                    ndef.addEventListener("reading", ({ message }) => {
                        const decoder = new TextDecoder();

                        for (const record of message.records) {
                            switch (record.recordType) {
                                case "text": {
                                    const textDecoder = new TextDecoder(record.encoding);
                                    scannedNFCValue.value = textDecoder.decode(record.data);
                                    //Se ho scansionato qualcosa e non sono in blocco procedo con la timbratura
                                    if (scannedNFCValue.value && !blockScan.value) {
                                        handleTimbraturaNfc(scannedNFCValue.value);
                                    }
                                    break;
                                }
                                default:
                                    openToast("Impossibile effettuare la scansione", "toast_danger");
                            }
                        }
                    });
                } catch (error) {
                    console.log(error);
                    showNfcContainer.value = false;
                    blockScan.value = false;
                }
            } else {
                showNfcContainer.value = false;
                blockScan.value = false;
                openToast("La scansione con NFC non è supportata", "toast_danger");
            }
        };

        /**
         * ! Apre modale per creare presenza completa con data ed ora inizio e fine + selezione cliente
         */
        async function createPresenza() {
            if (dipendenteSettings.value.dipendenti_presenza_automatica == "1") {
                openToast("Le tue timbrature vengono generate in automatico dal sistema", "toast_info");
                return;
            }

            const modal = await modalController.create({
                component: ModalCreaPresenza,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    presenze.value = [detail.data, ...presenze.value];
                    openToast("Presenza inserita correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**************************************************************
         *
         * ! Gestione stampa cliente o meno se associato alla presenza
         *
         **************************************************************/
        function printCustomer(timbratura) {
            if (timbratura.customers_company) {
                const trimmedString =
                    timbratura.customers_company.length > 35 ? timbratura.customers_company.substring(0, 32) + "..." : timbratura.customers_company;
                return ` - ${trimmedString}`;
            } else if (timbratura.customers_name && timbratura.customers_last_name) {
                const reference = `${timbratura.customers_name} ${timbratura.customers_last_name}`;
                const trimmedString = reference.length > 35 ? reference.substring(0, 32) + "..." : reference;
                return ` - ${trimmedString}`;
            }
        }

        /**
         * ! Gestione straordinario manuale e richiesta dati reparti
         */
        const reparti = ref([]);
        const repartoSelezionato = ref(null);
        async function loadReparti() {
            apiReparti
                .getUserReparti(dipendenteID)
                .then((response) => {
                    if (response.data.length != 0) {
                        reparti.value = response.data;
                        //Se sono associato ad un solo reparto lo seleziono in automatico e non serve che mostro la select reparti
                        if (response.data.length == 1) {
                            repartoSelezionato.value = response.data[0].reparti_id;
                        }
                        console.log(repartoSelezionato.value);
                    } else {
                        reparti.value = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta dei reparti", "danger");
                });
        }
        loadReparti();

        /**
         * Setta il reparto, obbligatorio prima di inserire la presenza
         * @param reparto_id string of selected reparto
         */
        function setReparto(reparto_id) {
            repartoSelezionato.value = reparto_id;
        }

        const showStraordinario = ref(false);
        const selectedPresenza = ref(null);
        //const straordinarioValue = ref(null);
        const currentHourStraordinario = ref(0);
        const currentMinutesStraordinario = ref(0);

        function convertiInOreEMinuti(valore) {
            // Calcola le ore prendendo la parte intera del valore
            const ore = Math.floor(valore);
            // Calcola i minuti prendendo la parte decimale del valore e moltiplicandoli per 60
            const minuti = Math.round((valore - ore) * 60);
            console.log(ore + " ore " + minuti + " minuti");
            // Restituisci il tempo formattato
            currentHourStraordinario.value = ore;
            currentMinutesStraordinario.value = minuti;
        }

        function apriStraordinario(presenza) {
            //Blocco a priori se è legata ad una richiesta
            if (presenza.presenze_richiesta) {
                openToast("Questa presenza è stata creata in automatico dal sistema e non è modificabile", "toast_danger");
                return;
            }

            if (presenza.presenze_data_fine || presenza.presenze_data_fine == "") {
                selectedPresenza.value = { ...presenza };
                // Converto il valore in ore e minuti
                convertiInOreEMinuti(selectedPresenza.value.presenze_straordinario);
                // Mostro modale
                //straordinarioValue.value = selectedPresenza.value.presenze_straordinario;
                showStraordinario.value = true;
            } else {
                openToast("Potrai inserire lo staordinario solamente dopo aver chiuso la presenza", "toast_danger");
            }
        }
        function chiudiStraordinario() {
            selectedPresenza.value = null;
            //straordinarioValue.value = null;
            // Reset ore e minuti straordinario
            currentHourStraordinario.value = 0;
            currentMinutesStraordinario.value = 0;

            showStraordinario.value = false;
        }

        /*******************************************
         *
         * ! EDIT PRESENZA
         *
         ******************************************/
        function editPresenza() {
            const oreStraordinario = parseFloat(currentHourStraordinario.value);
            const minutiStraordinario = parseFloat(currentMinutesStraordinario.value);
            // console.log("Straordinario originale: " + selectedPresenza.value.presenze_straordinario);
            // console.log(`Ore: ${oreStraordinario} - Minuti: ${minutiStraordinario}`);
            // Converto nuovamente in decimale
            const straordinarioTmp = parseFloat(oreStraordinario + minutiStraordinario / 60).toFixed(2);

            //console.log(straordinarioTmp);

            //Se devo poter bloccare la modifica dello straordinario
            if (bloccaModificaStraordinario.value) {
                //Se ho modificato lo straordinario e la presenza è stata creata prima di oggi blocco (posso modificare straordinario entro mezzanotte)
                const dataPresenzaOriginale = moment(selectedPresenza.value.presenze_data_inizio);
                const dataConfronto = moment();
                const diffConfronto = dataConfronto.diff(dataPresenzaOriginale, "hours");

                if (diffConfronto > 24 && selectedPresenza.value.presenze_straordinario != straordinarioTmp) {
                    openToast("Gli straordinari sono modificabili solo nella stessa giornata della presenza", "toast_danger");
                    return;
                }
            }

            if (dipendenteSettings.value.dipendenti_consenti_straordinari === "1") {
                if (currentHourStraordinario.value && (parseFloat(currentHourStraordinario.value) < 0 || parseFloat(currentHourStraordinario.value) > 23)) {
                    openToast("Devi inserire un valore compreso tra 0 e 23 per salvare le ore di straordinario", "toast_danger");
                    return;
                }
                if (
                    currentMinutesStraordinario.value &&
                    (parseFloat(currentMinutesStraordinario.value) < 0 || parseFloat(currentMinutesStraordinario.value) > 59)
                ) {
                    openToast("Devi inserire un valore compreso tra 0 e 59 per salvare i minuti di straordinario", "toast_danger");
                    return;
                }
                if (
                    (!currentMinutesStraordinario.value && parseFloat(currentMinutesStraordinario.value) != 0) ||
                    (!currentHourStraordinario.value && parseFloat(currentHourStraordinario.value) != 0)
                ) {
                    openToast("Non puoi salvare gli stroardinari senza compilare entrambi i campi", "toast_danger");
                    return;
                }
                /* openToast("Devi inserire un valore maggiore o uguale a 0 per salvare lo straordinario", "toast_danger");
                return; */
            }

            //se devo modificare straordinario faccio controllo che sia positivo
            /*if (
                dipendenteSettings.value.dipendenti_consenti_straordinari === "1" &&
                selectedPresenza.value &&
                (selectedPresenza.value.presenze_straordinario < 0 || selectedPresenza.value.presenze_straordinario === "")
            ) {
                openToast("Devi inserire un valore maggiore o uguale a 0 per salvare lo straordinario", "toast_danger");
                return;
            } else { */
            const presenzaId = selectedPresenza.value.presenze_id;
            //const straordinario = straordinarioValue.value;
            const note = selectedPresenza.value.presenze_note ? selectedPresenza.value.presenze_note : null;

            apiPresenze
                .editPresenza(presenzaId, straordinarioTmp, note)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        const res = response.data.data[0];
                        //Aggiorno presenza appena modificata e chiudo la modale
                        const presenzaModificata = presenze.value.find((element) => element.presenze_id === res.presenze_id);
                        if (presenzaModificata) {
                            presenzaModificata.presenze_straordinario = res.presenze_straordinario;
                            presenzaModificata.presenze_note = res.presenze_note;
                            openToast("Presenza modificata correttamente", "toast_success");
                            //straordinarioValue.value = null;
                            currentHourStraordinario.value = 0;
                            currentMinutesStraordinario.value = 0;
                            showStraordinario.value = false;
                        }
                    } else if (response.data.status === 8) {
                        openToast(response.data.message, "toast_danger");
                        return;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la modifica della presenza", "toast_danger");
                });
            //}
        }

        /************************************************
         *
         * ! APRE MODALE PER INSERIMENTO GIUSTIFICATIVO
         *
         ************************************************/
        const showGiustificativo = ref(false);
        function apriGiustificativo(presenza) {
            //Blocco a priori se è legata ad una richiesta
            if (presenza.presenze_richiesta) {
                openToast("Questa presenza è stata creata in automatico dal sistema e non è modificabile", "toast_danger");
                return;
            }

            if (presenza.presenze_anomalia && presenza.presenze_anomalia === "1") {
                selectedPresenza.value = { ...presenza };
                showGiustificativo.value = true;
            }
        }
        function chiudiGiustificativo() {
            selectedPresenza.value = null;
            showGiustificativo.value = false;
        }
        async function saveGiustificativo() {
            if (selectedPresenza.value && selectedPresenza.value.presenze_note && selectedPresenza.value.presenze_note.length > 0) {
                try {
                    const giustificativo = selectedPresenza.value.presenze_note;
                    const presenzaId = selectedPresenza.value.presenze_id;

                    const res = await apiPresenze.setGiustificativo(presenzaId, giustificativo);
                    //console.log(res);
                    if (res.data.status === 0) {
                        openToast(`${res.data.txt}`, "toast_danger");
                        return;
                    } else if (res.data.status === 1) {
                        openToast(`${res.data.txt}`, "toast_success");

                        const presenzaModificata = presenze.value.find((element) => element.presenze_id === res.data.presenza.presenze_id);
                        if (presenzaModificata) {
                            presenzaModificata.presenze_note = res.data.presenza.presenze_note;
                            chiudiGiustificativo();
                        }
                    }
                } catch (error) {
                    console.log(error);
                    openToast("Si è verificato un errore durante il salvataggio del giustificativo", "toast_danger");
                }
            } else {
                openToast("Devi compilare il giustificativo prima di salvare", "toast_danger");
            }
        }

        /******************************************
         *
         * ! DELETE SELECTED PRESENZA
         *
         *******************************************/
        async function deletePresenza(presenza_id) {
            try {
                const response = await apiPresenze.deletePresenza(presenza_id, userID);
                //console.log(response);
                if (response.status === 200 && response.data.status === 8) {
                    openToast(response.data.message, "toast_danger");
                } else if (response.status === 200 && response.data.status === 0) {
                    presenze.value = presenze.value.filter((presenza) => presenza.presenza_id != presenza_id);
                    selectedPresenza.value = null;
                    showStraordinario.value = false;
                    openToast("Presenza cancellata correttamente", "toast_success");
                } else {
                    openToast("Errore durante la cancellazione della presenza", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la cancellazione della presenza", "toast_danger");
            }
        }

        /**
         * ! Apre pagiuna per inserire la reperibilità e visualizzare quelle inserite in elenco,
         * ! Se comunicata nell'ultima ora mostro button per poterla eliminare
         */
        async function openReperibilita() {
            const modal = await modalController.create({
                component: Reperibilita,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //richieste.value = [detail.data, ...richieste.value];
                    openToast("Reperibilita comeunicata correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        //2) CONTROLLO QUALI PULSANTI DISABILITARE
        /**
         * Se ho timbratura di oggi senza presenze_ora_fine vuol dire che ho timbrato SOLO l'entrata -> disabilito entrata
         * Se ho timbratura di oggi ed ha anche presenze_ora_fine vuol dire che ho timbrato entrati -> riabilito entrata per successivo utilizzo
         */
        function checkTimbrature() {
            const oggi = moment().format("YYYY-MM-DD");
            const ieri = moment()
                .subtract(1, "day")
                .format("YYYY-MM-DD");

            const presenzePrecedenti = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).isSameOrBefore(oggi, "day") &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            );

            if (presenzePrecedenti) {
                entrataTimbrata.value = true;
            } else {
                entrataTimbrata.value = false;
            }

            /* const presenzeOggi = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).format("YYYY-MM-DD") === oggi &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            );
            const presenzeIeri = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).format("YYYY-MM-DD") === ieri &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            );

            if (presenzeOggi || presenzeIeri) {
                entrataTimbrata.value = true;
            } else {
                entrataTimbrata.value = false;
            } */
        }

        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        async function loadTimbrature() {
            loading.value = true;
            apiPresenze
                .getTimbrature(dipendenteID)
                .then((response) => {
                    //console.log("TIMBRATURE EFFETTUATE: ", response);
                    presenze.value = response;
                    if (response.length > 0) {
                        checkTimbrature();
                    } else {
                        entrataTimbrata.value = false;
                        uscitaTimbrata.value = false;
                    }
                    //checkTimbrature(response);
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la richiesta delle presenze", "danger");
                })
                .finally(() => {
                    loading.value = false;
                });
        }

        /**
         * ! CONTROLLO presenze
         * * Se trovo almeno una presenza di oggi senza data fine vuol dire che sto timbrando l'uscita (posso averne fatte altre oggi ma sempre entrando ed uscendo)
         * * Se non la trovo vuol dire che sto timbrando l'entrata (o è la prima/unica della giornata o sto entrando nuovamente)
         */
        const confirmationText = ref("");
        function checkPresenze() {
            const presenzeOggi = presenze.value.some(
                (presenza) =>
                    moment(presenza.presenze_data_inizio).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                    (presenza.presenze_ora_fine === null || presenza.presenze_ora_fine === "")
            );
            const orario = moment().format("HH:mm");

            if (presenzeOggi) {
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare l'uscita alle ore ${orario}`;
            } else {
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare l'entrata alle ore ${orario}`;
            }
        }

        /******************************************
         *
         * ! QR Code handler
         *
         *******************************************/
        const state = reactive({
            data: null,
        });
        const showQrCode = ref(false);
        const disabledQrCodeButton = ref(false);

        //Stampo informazioni per avvisare il dipendente della timbratura che sta per effettuare (entrata o uscita)
        function onDecode(data) {
            state.data = data;
            if (data) {
                checkPresenze();

                if (data) {
                    let qrValue = data;
                    disabledQrCodeButton.value = true;

                    apiPresenze
                        .scanQrCode(qrValue, dipendenteID)
                        .then((response) => {
                            if (response.data.status === 0) {
                                //error
                                openToast(response.data.txt, "toast_danger");
                            } else {
                                if (response.data.tipo === "entrata") {
                                    //aggiungo nuova presenza
                                    presenze.value = [response.data.data, ...presenze.value];
                                    entrataTimbrata.value = true;
                                } else {
                                    //devo modificare presenza e chiudo la modale
                                    const presenzaModificata = presenze.value.find((element) => element.presenze_id === response.data.data.presenze_id);
                                    if (presenzaModificata) {
                                        presenzaModificata.presenze_data_fine = response.data.data.presenze_data_fine;
                                        presenzaModificata.presenze_ora_fine = response.data.data.presenze_ora_fine;
                                        presenzaModificata.presenze_straordinario = response.data.data.presenze_straordinario;
                                    }
                                    entrataTimbrata.value = false;
                                }
                                openToast(response.data.txt, "toast_success");
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            openToast("Errore durante la scansione del codice QR", "toast_danger");
                        })
                        .finally(() => {
                            data = null;
                            state.data = null;
                            showQrCode.value = false;
                            qrValue = null;
                            confirmationText.value = "";
                            disabledQrCodeButton.value = false;
                        });
                } else {
                    openToast("Inquadra il codice QR prima di salvare la presenza", "toast_danger");
                }
            }
        }
        function openQrCode() {
            if (dipendenteSettings.value.dipendenti_presenza_automatica == "1") {
                openToast("Le tue timbrature vengono generate in automatico dal sistema", "toast_info");
                return;
            }
            showQrCode.value = true;
        }
        function chiudiQrCode() {
            showQrCode.value = false;
            state.data = null;
            confirmationText.value = "";
            disabledQrCodeButton.value = false;
        }
        function handleQrCodeTimbratura() {
            //console.log(state.data);
            if (state.data) {
                //let qrValue = state.data.slice(-5); //non dovrò più prendere solo le ultime 5 ma tutta la stringa
                let qrValue = state.data;
                disabledQrCodeButton.value = true;

                apiPresenze
                    .scanQrCode(qrValue, dipendenteID)
                    .then((response) => {
                        if (response.data.status === 0) {
                            //error
                            openToast(response.data.txt, "toast_danger");
                        } else {
                            if (response.data.tipo === "entrata") {
                                //aggiungo nuova presenza
                                presenze.value = [response.data.data, ...presenze.value];
                                entrataTimbrata.value = true;
                            } else {
                                //devo modificare presenza e chiudo la modale
                                const presenzaModificata = presenze.value.find((element) => element.presenze_id === response.data.data.presenze_id);
                                if (presenzaModificata) {
                                    presenzaModificata.presenze_data_fine = response.data.data.presenze_data_fine;
                                    presenzaModificata.presenze_ora_fine = response.data.data.presenze_ora_fine;
                                    presenzaModificata.presenze_straordinario = response.data.data.presenze_straordinario;
                                }
                                entrataTimbrata.value = false;
                            }
                            openToast(response.data.txt, "toast_success");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        openToast("Errore durante la scansione del codice QR", "toast_danger");
                    })
                    .finally(() => {
                        state.data = null;
                        showQrCode.value = false;
                        qrValue = null;
                        confirmationText.value = "";
                        disabledQrCodeButton.value = false;
                    });
            } else {
                openToast("Inquadra il codice QR prima di salvare la presenza", "toast_danger");
            }
        }

        /**********************************************************************************************************************/
        //5) UTILS
        /******************************************************
         *
         * ! Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         *
         ******************************************************/
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }
        /******************************************************
         *
         * ! Format date from YYYY/MM/DD HH:mm:ss to HH:mm
         *
         ******************************************************/
        function timeFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("HH:mm");
            } else {
                return "-";
            }
        }
        /******************************************************
         *
         * ! Set correct background for richieste status
         *
         ******************************************************/
        const statoReperibilita = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 0) {
                    //attesa approvazione
                    className = "badge_danger";
                } else if (statusId == 1) {
                    //approvato
                    className = "badge_success";
                }
                return className;
            };
        });

        /**********************************************************************************************************************/
        //4A) TIMBRATURA USCITA - Edit della presenza di oggi e senza presenze_ora_fine
        const isSavingTimbraturaUscita = ref(false);
        function timbraUscita(uscitaData, uscitaOra) {
            isSavingTimbraturaUscita.value = true;

            const ora_uscita = uscitaOra;
            const data_uscita = uscitaData;
            const data = new FormData();
            data.append("presenze_dipendente", dipendenteID);
            data.append("presenze_data_inizio", data_uscita);
            data.append("presenze_ora_fine", ora_uscita);

            apiPresenze
                .chiudiPresenza(dipendenteID, ora_uscita, "APP")
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        //Cerco la presenza appena modificata, la aggiorno e abilito di nuovo btn entrata
                        const presenzaDaChiudure = presenze.value.find((element) => element.presenze_id === response.data.uscita.presenze_id);
                        const presenzaChiusa = response.data.uscita;
                        if (presenzaDaChiudure) {
                            //console.log(presenzaDaChiudure);
                            presenzaDaChiudure.presenze_data_fine = presenzaChiusa.presenze_data_fine;
                            presenzaDaChiudure.presenze_ora_fine = presenzaChiusa.presenze_ora_fine;
                            presenzaDaChiudure.presenze_straordinario = parseFloat(presenzaChiusa.presenze_straordinario).toFixed(2);

                            entrataTimbrata.value = false;
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    openToast("Errore durante la timbratura", "toast_danger");
                })
                .finally(() => {
                    isSavingTimbraturaUscita.value = false;
                });
        }
        //4) SALVO PRESENZA USCITA
        /**
         * Se ho effettuato una timbratura in entrata (la presenza SENZA ora_fine è quella di oggi)
         * allora posso fare una timbratura in uscita --> edit presenze impostando ora e data fine
         * altrimenti mostro errore
         */
        function handleTimbraUscita() {
            if (dipendenteSettings.value.dipendenti_presenza_automatica == "1") {
                openToast("Le tue timbrature vengono generate in automatico dal sistema", "toast_info");
                return;
            }

            const data_uscita = moment().format("YYYY-MM-DD");
            const ora_uscita = moment().format("HH:mm");
            timbraUscita(data_uscita, ora_uscita);
        }

        /* ===================================================================================================================== */
        //3 base) OTTIENE LA PRESENZA SE DIPENDNETE DEVE COMUNICARLA ASSIEME ALLA TIMBRATURA IN ENTRATA
        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000, //max ms the device is allowed to take in order to return the position
            maximumAge: 0, //max ms of a possible cached position that is accetptable to return. 0 means no cached position
        };
        async function getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        return resolve(position);
                    },
                    function(error) {
                        if (error.code === error.PERMISSION_DENIED) {
                            entrataTimbrata.value = false;
                            openToast("GPS spento oppure hai negato l'autorizzazione alla geolocalizzazione.", "toast_danger");
                        } else {
                            // Si è verificato un altro tipo di errore durante la geolocalizzazione
                            entrataTimbrata.value = false;
                            openToast("Si è verificato un errore durante la geolocalizzazione.", "toast_danger");
                        }
                        return reject(error);
                    }
                )
            );
        }

        //3A) SALVA PRESENZA IN ENTRATA
        const isSavingTimbraturaEntrata = ref(false);
        async function timbraEntrata(entrataData, entrataOra, reparto, latitude, longitude) {
            isSavingTimbraturaEntrata.value = true;

            const oraEntrata = entrataOra;
            const dataEntrata = entrataData;

            try {
                const response = await apiPresenze.timbraEntrata(dipendenteID, oraEntrata, reparto, latitude, longitude, "APP");

                if (response.data.status === 0) {
                    entrataTimbrata.value = false;
                    openToast(response.data.txt, "toast_danger");
                } else if (response.data.status === 1) {
                    openToast(response.data.txt, "toast_success");
                    presenze.value = [response.data.entrata, ...presenze.value];
                    entrataTimbrata.value = true;
                }
            } catch (error) {
                console.error(error);
                entrataTimbrata.value = false;
                openToast("Errore durante la timbratura", "toast_danger");
            } finally {
                isSavingTimbraturaEntrata.value = false;
            }

            /* apiPresenze
                .timbraEntrata(dipendenteID, oraEntrata, reparto, latitude, longitude, "APP")
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        entrataTimbrata.value = false;
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        openToast(response.data.txt, "toast_success");
                        presenze.value = [response.data.entrata, ...presenze.value];
                        entrataTimbrata.value = true;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    entrataTimbrata.value = false;
                    openToast("Errore durante la timbratura", "toast_danger");
                }).finally(() => {
                    isSavingTimbraturaEntrata.value = false;
                }) ;*/
        }

        //3) GESTISCE PRESENZA IN ENTRATA ( --------> [HR - APP] - Presenza in entrata ed orari calendario )
        async function handleTimbraEntrata() {
            if (dipendenteSettings.value.dipendenti_presenza_automatica == "1") {
                openToast("Le tue timbrature vengono generate in automatico dal sistema", "toast_info");
                return;
            }

            const data_ordierna = moment().format("YYYY-MM-DD");
            const oraEntrata = moment().format("HH:mm");
            let pos = null;

            //Se devo comunicare prima il reparto controllo di averlo selezionato
            if (dipendenteSettings.value.dipendenti_dichiara_reparto === "1" && !repartoSelezionato.value) {
                openToast("Prima di timbrare devi selezionare il reparto di riferimento", "toast_danger");
                return;
            }
            //Se devo comunicare le coordinate
            if (dipendenteSettings.value.dipendenti_geolocalizzazione === "1") {
                mustDeclareGeolocation.value = true;
                entrataTimbrata.value = true;

                pos = await getPosition(geoOptions);
                if (pos && pos.coords) {
                    await timbraEntrata(data_ordierna, oraEntrata, repartoSelezionato.value, pos.coords.latitude, pos.coords.longitude, "APP");
                }
            } else if (dipendenteSettings.value.dipendenti_geolocalizzazione === "1" && !pos) {
                openToast("Prima di timbrare devi permettere e/o attivare la geolocalizzazione", "toast_danger");
                return;
            } else {
                //Se nel mio profilo c'è un orario di inizio e fine per la giornata di oggi salva l'entrata altrimenti errore
                await timbraEntrata(data_ordierna, oraEntrata, repartoSelezionato.value);
            }
        }

        async function checkSavedToken(userId, token) {
            //Vedo se il token è già stato salvato in db per questo utente, se so li torno altrimenti lo creod
            const resCheck = await apiNotifiche.checkToken(userID, token);
            if (resCheck.status === 200 && resCheck.data.status === 0 && resCheck.data.data.length != 0) {
                console.log("token già presente in db");
                return false;
            } else if (resCheck.status === 200 && resCheck.data.status === 0 && resCheck.data.data.length === 0) {
                console.log("creo token...");
                const date = moment().format("YYYY-MM-DD HH:mm:ss");
                const res = await apiNotifiche.saveToken(userID, token, date);
                return res.data.data;
            } else {
                openToast("Impossibile controllare se il token delle notifiche è già associato all'utente", "toast_danger");
                return;
            }
        }

        /*         const message = ref("");
        const messaging = getMessaging();

        async function configureFc() {
            console.log(messaging);

            try {
                const currentToken = await getToken(messaging, {
                    vapidKey: "BPtKTjfgRpWrNUny6st5B1o1twyXfdYzodmt_c_qc2-4ZZ1i4ZsByFPVFC1ZCC917PXiCXzM4Mk1bdtMsuD-wU0",
                });
                console.log("Firebase Token:", currentToken);

                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    //const res = await apiNotifiche.saveToken(userID, currentToken);
                    const res = await checkSavedToken(userID, currentToken);
                    console.log(res);
                } else {
                    // Show permission request UI
                    console.log("No registration token available. Request permission to generate one.");
                    openToast("Impossibile registrarsi per ricevere notifiche", "toast_danger");
                }
            } catch (error) {
                console.log("FC Error: " + error);
                alert(error);
                openToast("Impossibile registrarsi per ricevere notifiche. Contattare l'assistenza", "toast_danger");
            }
        }

        // Gestisci il messaggio in arrivo
        onMessage(messaging, (payload) => {
            console.log("Messaggio ricevuto: ", payload);
            message.value = payload.notification.body;
            alert(messaging);
            alert(payload);
        });

        async function requestNotificationPermission() {
            try {
                const permission = await Notification.requestPermission();

                if (permission === "granted") {
                    // L'utente ha concesso i permessi per le notifiche
                    console.log("Permessi per le notifiche concessi");
                    await configureFc();
                } else if (permission === "denied") {
                    // L'utente ha negato i permessi per le notifiche
                    console.log("Permessi per le notifiche negati");
                    openToast("Permessi per le notifiche negati. Concedi le autorizzazione necessarie dalle impostazioni dell'applicazione", "toast_danger");
                } else {
                    // L'utente non ha ancora risposto alla richiesta di permessi
                    console.log("Risposta agli utenti in sospeso");
                }
            } catch (error) {
                console.error("Errore durante la richiesta di permessi per le notifiche", error);
            }
        } */

        /******************************************************************************************
         *
         * ! GESTIONE CONTROLLO CIRCOLARI DA LEGGERE
         * ? 21/03/2024 - Aggiunto controllo per visualizzare le circolari da leggere
         ******************************************************************************************/
        const circolari = ref([]);
        const countCircolari = ref(0);
        const showModalCircolari = ref(false);
        const textCircolari = ref("");

        async function loadCircolari() {
            try {
                const res = await apiDocumenti.checkCircolari(userID);

                if (res.status === 200 && res.data.status === 0) {
                    circolari.value = res.data.data;
                    countCircolari.value = res.data.data.length;

                    if (countCircolari.value > 0) {
                        showModalCircolari.value = true;

                        if (countCircolari.value == 1) {
                            textCircolari.value = `Hai ${countCircolari.value} circolare da leggere`;
                        } else {
                            textCircolari.value = `Hai ${countCircolari.value} circolari da leggere`;
                        }
                    } else {
                        showModalCircolari.value = false;
                        textCircolari.value = "";
                    }
                } else {
                    circolari.value = [];
                    showModalCircolari.value = false;
                    countCircolari.value = 0;
                    textCircolari.value = "";
                }
            } catch (error) {
                circolari.value = [];
                showModalCircolari.value = false;
                countCircolari.value = 0;
                textCircolari.value = "";
            }
        }

        function chiudiCircolari() {
            circolari.value = [];
            showModalCircolari.value = false;
            countCircolari.value = 0;
        }

        /* ===================================================================================================================== */
        //1) SCARICO TIMBRATURE EFFETTUATE e controllo se disabilitare entrata
        onMounted(() => {
            loadTimbrature();
            //console.log($showTasks);
            //requestNotificationPermission();
            //configureFc();
        });

        /***********************************************************
         *
         * ! Ad ogni accesso alla pagina controllo se abilitare/disabilitare i buttons e richiedo info dipendente e modulo
         *
         **********************************************************/
        const dipendente = ref(null);
        async function loadDipendente() {
            loading.value = true;
            try {
                const res = await apiDipendenti.getDipendente(dipendenteID);
                if (res.status === 200 && res.data.status === 0) {
                    dipendente.value = res.data.data;
                } else {
                    openToast("Si è verificato un durante il caricamento delle informazioni del dipendente", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un durante il caricamento delle informazioni del dipendente", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        onIonViewWillEnter(() => {
            loadDipendente();
            getDipendenteData();
            loadTimbrature();
            loadImpostazioniHr();
            loadCircolari();
        });

        onBeforeUnmount(() => {
            clearInterval(timer.value);
        });

        return {
            refresh,
            menu,
            arrowBackCircle,
            arrowForwardCircle,
            phonePortrait,
            qrCodeOutline,
            calendar,
            clipboard,
            person,
            loading,
            dateFormat,
            timeFormat,
            richieste,
            loadTimbrature,
            //Richieste
            saldoRichieste,
            //Presenze
            presenze,
            handleTimbraEntrata,
            handleTimbraUscita,
            entrataTimbrata,
            uscitaTimbrata,
            //Reperibilità
            statoReperibilita,
            //reperibilita,
            openMenu,
            time,
            //Pagina reperibilita
            openReperibilita,
            //Straordinario
            apriStraordinario,
            chiudiStraordinario,
            selectedPresenza,
            /* Rimosso in favore di gestione con ore e minuti e conversione nel frontend prima di invio dati  
            straordinarioValue,
            */
            currentHourStraordinario,
            currentMinutesStraordinario,
            showStraordinario,
            bloccaModificaStraordinario,
            consentiModificaStraordinario,
            //salvaStraordinario,
            editPresenza,
            todayDate,
            //QR code data,
            openQrCode,
            showQrCode,
            QrStream,
            ...toRefs(state),
            onDecode,
            chiudiQrCode,
            handleQrCodeTimbratura,
            confirmationText,
            disabledQrCodeButton,
            //Creazione presenza completa
            createPresenza,
            printCustomer,
            //Selezione reparto per effettuare la presenza
            repartoSelezionato,
            reparti,
            setReparto,
            //MOSTRA / NASCONDI BUTTON IN BASE AI SETTINGS DEL DIPENDENTE
            dipendenteSettings,
            deletePresenza,
            //NFC
            nfcScan,
            blockScan,
            showNfcContainer,
            chiudiNfcContainer,
            //Giustificativo
            apriGiustificativo,
            chiudiGiustificativo,
            showGiustificativo,
            saveGiustificativo,
            // circolari
            countCircolari,
            textCircolari,
            showModalCircolari,
            chiudiCircolari,
            dayName,
            setRichiesta,
            // Badge dipendente
            openBadgeModal,
            chiudiBadgeModal,
            badgeModalVisible,
            b64Image,
            //Dati dipendente per mostrare saldi aggiornati
            dipendente,
            //Flag disabled buttons
            isSavingTimbraturaEntrata,
            isSavingTimbraturaUscita,
        };
    },
};
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_presenze_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
}
.btn_presenza_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}
.btn_presenza {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Timbrature */
.btn_entrata,
.btn_uscita {
    margin-bottom: 16px;
}

.timbratura_container {
    width: 100%;
}

.timbratura_first_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}

.timbratura_data {
    font-weight: 500;
    font-size: 14px;
}

.data_richiesta .orari {
}

.reperibilita {
    width: 90px;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 4px;
    color: #f1f5f9;
    display: flex;
    justify-content: center;
}

.dichiarata {
    background: #16a34a;
}

.non_dichiarata {
    background: #dc2626;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    /* to be used for new richiesta btn */
    min-height: 100%;
    background: #f2f2f2;
}

.no_presenze_container {
    width: 100%;
    padding: 16px;
}

.no_presenze {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

ion-button {
    --color: #ffffff;
}
ion-button.btn_custom {
    --background: #16a34a;
    --background-activated: #15803d;
    --background-hover: #15803d;
    --background-focused: #15803d;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    width: 45%;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

ion-button.btn_reperibilita {
    /*--background: #0ea5e9;*/
    --background: #0087ca;
    --background-activated: #0576ae;
    --background-hover: #0576ae;
    --background-focused: #0576ae;
    --box-shadow: none;
    --border-radius: 4px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    --ripple-color: none;
    --color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.container_presenze {
    width: 100%;
    padding: 16px;
    padding-top: 0px;
}

.presenza {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 8px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}
.presenza .presenza_sx {
    display: flex;
    gap: 8px;
}

.btn_timbratura .icon,
.presenza .icon {
    background: #1c90cf1a;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_timbratura .icon ion-icon,
.presenza .icon ion-icon {
    font-size: 20px;
    color: #1c90cf;
}

.presenza .title {
    font-size: 14px;
    font-weight: 600;
    color: #1d1d1b;
    margin-bottom: 8px;
    text-transform: capitalize;
}
.presenza .data {
    font-size: 12px;
    font-weight: 500;
    color: #7f8085;
}

.presenza .orari {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #1d1d1b;
}
.presenza .orari .value {
    margin-bottom: 4px;
}
.presenza .orari .selected {
    font-size: 12px;
    font-weight: 700;
    color: #1d1d1b;
}

.presenza .straordinario {
    margin-top: 4px;
    font-size: 12px;
    color: #6b7280;
}
.presenza .anomalia {
    /*margin-top: 4px;*/
    font-size: 10px;
    color: #ef4444;
    background-color: #ffd9d9;
    border-radius: 4px;
    padding: 2px 6px;
}

.presenza .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.presenza .badge_success {
    background-color: #22c55e;
}

.presenza .badge_warning {
    background-color: #f97316;
}

.presenza .badge_danger {
    background-color: #ef4444;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

.bg_dark {
    /*background: linear-gradient(190.74deg, #1c90cf 0%, #1672a4 7.88%, #0e4766 29.73%, #000000 75.22%);*/
    background: linear-gradient(190.74deg, #086fa3 0%, #1672a4 7.88%, #086fa3 29.73%, #000000 75.22%);
    /* background-image: url("/assets/bg_wave.png");
    background-size: cover;
    background-position: center; */
    position: relative;
    height: 220px;
    /*margin-bottom: 36px;*/
}
.timer_clock {
    width: 100%;
    padding: 20px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    color: #ffffff;
    /* color: #0a90d4; */
    font-weight: bold;
    font-size: 52px;
}
.today_date {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #0a90d4;
    font-weight: bold;
    font-size: 24px;
    /*margin-bottom: 16px;*/
    margin-bottom: 24px;
}
.svg_wave {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    z-index: 99999;
    width: 110%;
}
.btn_badge_container {
    z-index: 99999;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_badge_container .btn_badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 14px 20px;
    border: 2px solid #e36f30;
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0px 2px 5px 0px #00000040;
    color: #e36f30;
    font-size: 17px;
    font-weight: 500;
}

/* Btns */
.section_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 20px;
    /*margin-bottom: 10px;*/
}

/* Btns timbratura */
.buttons_timbrature_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}
.btn_timbratura {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    background-color: #ffffff;
    color: #1d1d1b;
    box-shadow: 0px 2px 5px 0px #00000040;
    transition: all 0.15s ease-in;
}

.btn_timbratura.btn_qr_code {
    margin-bottom: 8px;
}
/* .btn_timbratura:active {
    background-color: #086fa3;
    color: #ffffff;
} */
.btn_timbratura span {
    margin-left: 10px;
}
.btn_timbratura:disabled {
    opacity: 0.5;
}
.buttons_timbrature_container_qr_nfc {
    margin-bottom: 8px;
}
/* Btn NFC */
.btn_nfc {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1dacf4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_nfc:disabled {
    background-color: rgba(10, 144, 212, 0.5);
}
/* Btn reperibilita */
.btn_reperibilita {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #1dacf4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_reperibilita:active {
    background-color: #0a90d4;
    color: #ffffff;
}
/***********************************************
* 
* Card richieste
*
*************************************************/
.ratei_container {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.single_rateo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    padding: 16px 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px 0px #00000040;
}
.single_rateo .title {
    font-size: 14px;
    font-weight: 600;
}
.single_rateo .value {
    font-size: 20px;
    font-weight: 700;
}
.single_rateo.rateo_ferie {
    background: #3da50c26;
}
.single_rateo.rateo_rol {
    background: #eba00026;
}
.single_rateo.rateo_permessi {
    background: #6f2cac26;
}
.single_rateo.rateo_ferie .value {
    color: #3da50c;
}
.single_rateo.rateo_rol .value {
    color: #eba000;
}
.single_rateo.rateo_permessi .value {
    color: #6f2cac;
}

/** Modale per inserire straoridnario su ogni singola presenza */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.presenza .straordinario_button {
    font-size: 10px;
    font-weight: 600;
    /*color: #ffffff;*/
    color: #0a90d4;
    /*background-color: #0a90d4;*/
    padding: 4px 8px;
    border-radius: 4px;
}
.modal_straordinario {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 16px;
    z-index: 9999999999999999;
}
.body_modal_container {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 8px;
    color: #000000;
}
.body_modal_container.body_modal_container_badge {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.body_modal_container .field {
    margin-bottom: 24px;
}
.body_modal_container .title {
    font-weight: 500;
    margin-bottom: 12px;
}
.body_modal_container .title_qr {
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}
.body_modal_container .content {
    padding: 24px 0;
    text-align: center;
}
.body_modal_container .content .cta {
    color: #086fa3;
}

.btn_salva_straordinario {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #1c90cf;
    background-color: #0f7fbc24;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 24px;
}
.btn_salva_straordinario:disabled {
    /* background-color: rgba(10, 144, 212, 0.5);
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1); */
    opacity: 0.75;
    transition: all 0.15s ease-in;
}
.btn_close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ef4444;
    background-color: #ffd9d9;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 16px;
}

/**
* Qr Code style
 */
.qr_code {
    /* height: 400px;
  width: 400px;*/
    height: 300px;
    margin: auto;
}
/* .frame {
  border-style: solid;
  border-width: 2px;
  border-color: #0a90d4;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
} */

.confirmation_text {
    margin-top: 16px;
    font-weight: bold;
}

.selezione_reparto {
    display: flex;
    justify-content: center;
    padding: 8px 16px;
}
.selezione_reparto ion-select {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

.buttons_container_presenza {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.btn_qrcode {
    width: 100%;
}
/* Modal NFC Tag */

.modal_nfc {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 16px;
    z-index: 9999999999999999;
}
.nfc_container {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
}
.nfc_container .field {
    margin-bottom: 24px;
}
.nfc_container .title {
    font-weight: 500;
    margin-bottom: 12px;
}
.nfc_container .title_nfc {
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}
.nfc_container .help_text {
    color: #6b7280;
    text-align: center;
    margin-bottom: 16px;
}
.svg_container {
    padding: 0 52px;
    margin: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.svg_container svg {
    max-width: 100px;
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.picker_straordinario {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}
</style>
